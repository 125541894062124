/// <reference path="o365.pwa.declaration.shared.dexie.objectStores.Database.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { ObjectStore } from 'o365.pwa.declaration.shared.dexie.objectStores.ObjectStore.d.ts';
import type { Dexie } from 'o365.pwa.declaration.sw.dexie.d.ts';

import type * as DatabaseModule from 'o365.pwa.declaration.shared.dexie.objectStores.Database.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const indexedDBHandlerImportmapEntry = self.o365.getImportMapEntryFromImportUrl('o365.pwa.modules.sw.IndexedDBHandler.ts');

    class Database implements DatabaseModule.Database {
        static objectStoreDexieSchema: string = "&[appId+id],id,appId";

        public id!: string;
        public appId!: string;

        public version: number = 1;
        public installedSchema: { [key: string]: string } = {};

        public get name(): string {
            return `O365_PWA_${this.appId.toUpperCase()}_${this.id.toUpperCase()}`;
        }

        public get objectStores() {
            const database = this;

            return new Proxy<DatabaseModule.ObjectStores>(<DatabaseModule.ObjectStores>{
                getAll: async () => {
                    const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

                    return await IndexedDBHandler.getObjectStores(database.appId, database.id);
                }
            }, {
                get(target, prop, receiver) {
                    if (prop in target) {
                        return Reflect.get(target, prop, receiver);
                    }

                    return new Promise<ObjectStore | null>(async (resolve, reject) => {
                        try {
                            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declarations.sw.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

                            const objectStore = await IndexedDBHandler.getObjectStore(database.appId, database.id, prop.toString());

                            resolve(objectStore);
                        } catch (reason) {
                            reject(reason);
                        }
                    });
                }
            });
        }

        public get databaseSchema(): Promise<{ [key: string]: string }> {
            return new Promise(async (resolve, reject) => {
                try {
                    const objectStores = await this.objectStores.getAll();

                    const databaseSchema: { [key: string]: string } = {};

                    for (const objectStore of objectStores) {
                        const objectStoreSchema = await objectStore.schema;

                        // TODO: Add check if objectstore has app/database override

                        databaseSchema[objectStore.id] = objectStoreSchema;
                    }

                    resolve(databaseSchema);
                } catch (reason) {
                    reject(reason);
                }
            });
        }

        public get upgradeNeeded(): Promise<boolean> {
            return new Promise<boolean>(async (resolve, reject) => {
                try {
                    const newScheme = await this.databaseSchema;

                    const keys1 = Object.keys(this.installedSchema).sort();
                    const keys2 = Object.keys(newScheme).sort();

                    if (keys1.length !== keys2.length) {
                        resolve(true);
                    }

                    for (let i = 0; i < keys1.length; i++) {
                        const key1 = keys1[i];
                        const key2 = keys2[i];

                        if (key1 !== key2 || this.installedSchema[key1] !== newScheme[key2]) {
                            resolve(true);
                        }
                    }

                    resolve(false);
                } catch (reason) {
                    reject(reason);
                }
            });
        }

        public get dexieInstance(): Promise<InstanceType<typeof Dexie>> {
            return new Promise(async (resolve, reject) => {
                try {
                    const dexieInstance = new self.Dexie.latestVersion.Dexie(this.name);

                    dexieInstance.version(this.version).stores(this.installedSchema);

                    await dexieInstance.open();

                    resolve(dexieInstance);
                } catch (reason) {
                    reject(reason);
                }
            });
        }

        constructor(id: string, appId: string) {
            this.id = id;
            this.appId = appId;
        }

        public async save(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            await IndexedDBHandler.updateDatabase(this);
        }

        public async delete(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            await IndexedDBHandler.deleteDatabase(this);
        }

        public async forceReload(): Promise<Database | null> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            return await IndexedDBHandler.getDatabaseFromIndexedDB(this.appId, this.id);
        }

        // TODO: Look into passing in upgrade scripts
        public async initialize(): Promise<void> {
            if (await this.upgradeNeeded === false) {
                return;
            }

            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            await IndexedDBHandler.closeDexieInstance(this.appId, this.id);

            const databaseSchema = await this.databaseSchema;

            this.version++;

            this.installedSchema = databaseSchema;

            await this.dexieInstance;

            this.save();
        }
    }

    self.o365.exportScripts({ Database });
})();

